
export const imgurlvalue = [
    {
        url: 'https://api.tmskins.com/uploads/images/202404/08/217257_l1rrFhGpyHN7T7pT.png'
    },
    {
        url: 'https://api.tmskins.com/uploads/images/202404/08/217257_zZi77WKx26zBa6nh.png'
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_dxkmspDCscvhKVO9.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_Z1pj1YUFpusucWhu.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_PgkULvMz4bydhSpw.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_pJNNDAPlQxji7A9g.png"
    },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_liYHpsTESxR8sHXW.png"
    },
    // {
    //     url: "https://api.tmskins.com/uploads/images/202404/08/217257_71v5i0KUTDx2yYbL.png"
    // },
    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_j3f3fuqmXfjWdT6y.png"
    },

    // {
    //     url: "https://api.tmskins.com/uploads/images/202404/08/217257_45gYqmq2Y97e0NcM.png"
    // },

    {
        url: "https://api.tmskins.com/uploads/images/202404/08/217257_g8eqtD5J5D9Zr3a4.png"
    }
];
