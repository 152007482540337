<template>
  <div class="userbox">
    <div class="topbox">
      <div class="lbox">
        <div class="headimgbox" @click="showupdata">
          <img :src="user.avatar" alt="" />
        </div>
        <div class="userinfo">
          <div class="username">{{ user.name }}</div>
          <div class="userid">ID:{{ user.id }}</div>
        </div>
      </div>
      <div class="rbox">
        <div>
          <div class="price1">
            <Cprice :size="0.12" :price="user.bean" />
          </div>
          <div class="price2">
            <Cint :size="0.12" :price="user.integral" />
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <div
        class="itembox"
        v-for="item in itemlist"
        @click="showitem(item.id)"
        :key="item.id"
      >
        <div class="iconbox">
          <img :src="item.icon" alt="" />
        </div>
        <div class="titlebox">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="tipsbox">
      <div class="tipstext">
        <img src="../../assets/newImages/user-tipsicon.png" alt="" />
        <p>
          打不开Steam怎么办？
          <a href="https://www.bilibili.com/video/BV15D4y1X79w" target="_block"
            >查看教程</a
          >
        </p>
      </div>
      <div class="tipstext">
        <img src="../../assets/newImages/user-tipsicon.png" alt="" />
        <p>
          获取你的Steam链接
          <a
            href="https://steamcommunity.com/profiles/76561198294948713/tradeoffers/privacy#trade_offer_access_url"
            target="_block"
            >点击获取</a
          >
        </p>
      </div>
      <div class="tipstext">
        <img src="../../assets/newImages/user-tipsicon.png" alt="" />
        <p>
          取回前将您的库存设置为“公开”
          <a
            href="https://steamcommunity.com/profiles/76561198294948713/edit/settings/"
            target="_block"
          >
            查看</a
          >
        </p>
      </div>
    </div>
    <div class="logout">
      <p class="usertext">用户协议</p>
      <div class="logoutbox" @click="loginOut()">退出登录</div>
    </div>
    <!-- 实名认证 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="bindname"
    >
      <CompPopup @close="showitem(1)">
        <div class="frombox">
          <div class="fromtitle">实名认证</div>
          <div class="frominput">
            <div class="inputbox">
              <div class="inputtitle">姓名</div>
              <input
                class="put"
                type="text"
                placeholder="输入姓名"
                v-model="namevalue"
              />
            </div>
            <div class="inputbox">
              <div class="inputtitle">身份证</div>
              <input
                class="put"
                type="text"
                placeholder="请输入身份证号"
                v-model="nameID"
              />
            </div>
            <div class="tipstext">
              平台玩家必须满足18周岁才可使用平台服务和充值，请如实填写本人身份证实名信息，如果发现填写的身份信息非本人实名信息，平台有权冻结账号并回收
            </div>
          </div>
          <div class="btnbox">
            <div class="btn" @click="doentername">提交</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>

    <!-- 修改密码 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="passwordshow"
    >
      <CompPopup @close="showitem(4)">
        <div class="frombox">
          <div class="fromtitle">修改密码</div>
          <div class="frominput">
            <div class="inputbox">
              <div class="inputtitle">输入手机号</div>
              <input
                class="put"
                type="text"
                placeholder="输入手机号"
                v-model="phonevalue"
              />
            </div>
            <div class="inputbox">
              <div class="inputtitle">获取验证码</div>
              <input
                class="put"
                type="text"
                placeholder="请输入验证码"
                v-model="passyzm"
              />
              <div class="yzmput" @click="getpassyzm">
                {{ !isCounting ? "获取验证码" : "已发送" }}
                <span v-if="isCounting">{{ countdown }}</span>
              </div>
            </div>
            <div class="inputbox">
              <div class="inputtitle">请设置登录密码</div>
              <input
                class="put"
                type="text"
                placeholder="请输入6~12位，字母+数字的密码组合"
                v-model="newpass"
              />
            </div>
            <div class="inputbox">
              <div class="inputtitle">再次确认登录密码</div>
              <input
                class="put"
                type="text"
                placeholder="再次输入新密码"
                v-model="enterpass"
              />
            </div>
          </div>
          <div class="btnbox">
            <div class="bbtn" @click="dopass">提交</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
    <!-- 绑定邀请码 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="bindcode"
    >
      <CompPopup @close="showitem(6)">
        <div class="frombox">
          <div class="fromtitle">绑定邀请码</div>
          <div class="frominput">
            <div class="inputbox">
              <input
                class="put putc"
                type="text"
                placeholder="请输入您的邀请码"
                v-model="invite_code_bangding"
                v-if="user.inviter_id == 0"
              />
              <div class="put putc" v-if="user.inviter_id !== 0">
                {{ user.invite_code }}
              </div>
            </div>
            <div class="tipstext textc">邀请码绑定后无法修改或删除</div>
          </div>
          <div class="btnbox" v-if="user.inviter_id == 0">
            <div class="btn" @click="setinvite_code">提交</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
    <!-- 绑定Steam -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="bindsteam"
    >
      <CompPopup @close="showitem(7)">
        <div class="frombox">
          <div class="fromtitle">绑定Steam</div>
          <div class="frominput">
            <div class="inputbox">
              <div class="inputtitle">输入手机号</div>
              <input
                class="put"
                type="text"
                placeholder="输入手机号"
                v-model="phonevalue"
              />
            </div>
            <div class="inputbox">
              <div class="inputtitle">获取验证码</div>
              <input
                class="put"
                type="text"
                placeholder="请输入验证码"
                v-model="steamphoneyzm"
              />
              <div class="yzmput" @click="getsteamyzm">
                {{ !isCounting ? "获取验证码" : "已发送" }}
                <span v-if="isCounting">{{ countdown }}</span>
              </div>
            </div>
            <div class="inputbox">
              <div class="inputtitle">输入交易链接</div>
              <input
                class="put"
                type="text"
                placeholder="请输入您的交易链接"
                v-model="steam_url"
              />
              <div class="yzmput" @click="deletoldsteam">删除旧链接</div>
            </div>
            <a
              class="tipstext textc textd"
              href="https://steamcommunity.com/profiles/76561198294948713/tradeoffers/privacy#trade_offer_access_url"
              target="_block"
              >前往Steam获取链接</a
            >
          </div>
          <div class="btnbox">
            <div class="bbtn" @click="GetSteamUrl">提交</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
    <!-- 修改头像昵称 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="usershow"
    >
      <CompPopup @close="showupdata">
        <div class="frombox">
          <div class="userheadimg">
            <img :src="isupdataimg" alt="" />
          </div>
          <div class="usertitle">{{ user.name }}</div>

          <div class="changebox">
            <div
              class="typeitem"
              v-for="item in typelist"
              :key="item.id"
              @click="changeindex(item.id)"
              :class="{ actypeitem: item.id == acindex }"
            >
              {{ item.title }}
            </div>
          </div>

          <div class="frominput" v-show="acindex == 1">
            <div class="inputbox">
              <input
                class="put putc"
                type="text"
                placeholder="请输入新昵称"
                v-model="nikenamevalue"
              />
            </div>
          </div>
          <div class="btnbox" v-show="acindex == 1">
            <div class="btn" @click="updataname">更改</div>
          </div>
          <div class="frominput" v-show="acindex == 2">
            <div class="imgsbox">
              <div class="headimg" v-for="(v, i) in imgurlvalue" :key="i">
                <div
                  class="l_imgbox"
                  :class="changehead === i ? 'acimgbox' : ''"
                  @click="imgchange(v, i)"
                  :key="i"
                >
                  <img :src="v.url" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="btnbox" v-show="acindex == 2">
            <div class="btn" @click="updataimg">更改</div>
          </div>
        </div>
      </CompPopup>
    </van-overlay>
    <!-- 系统设置 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="settings"
    >
      <CompPopup @close="showitem(5)">
        <div class="frombox">
          <div class="fromtitle">系统设置</div>
          <div class="frominput">
            <div class="setbox">
              <p>背景音乐</p>
              <div class="setbut" @click="bgmusic">
                <div class="butbg1" v-show="bgmusictype == true">
                  <div class="buttext">NO</div>
                </div>
                <div class="butbg2" v-show="bgmusictype == false">
                  <div class="buttext">OFF</div>
                </div>
              </div>
            </div>
            <div class="setbox">
              <p>音效</p>
              <div class="setbut" @click="sound">
                <div class="butbg1" v-show="soundtype == true">
                  <div class="buttext">NO</div>
                </div>
                <div class="butbg2" v-show="soundtype == false">
                  <div class="buttext">OFF</div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnbox"></div>
        </div>
      </CompPopup>
    </van-overlay>
    <Tabbar />
  </div>
</template>

<script>
import {
  SteamUrl,
  Set_Name,
  set_inviter,
  checkNameAndCardId,
  getrechargeinfoData,
  Set_avatar,
  base64,
  PostSendSms,
  Reset_password,
  getArticleData,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/; //设置手机号正则表达式
var ID =
  /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/; //身份证正则
var reg = /^[\w]{6,12}$/; //密码正则最少8位最多12，包含字母数字
import { imgurlvalue } from "./index.js";
export default {
  data() {
    return {
      itemlist: [
        {
          id: 1,
          title: "实名认证",
          icon: require("../../assets/newImages/user-icon1.png"),
        },
        {
          id: 2,
          title: "高光时刻",
          icon: require("../../assets/newImages/user-icon2.png"),
        },
        {
          id: 3,
          title: "记录查询",
          icon: require("../../assets/newImages/user-icon3.png"),
        },
        {
          id: 4,
          title: "修改密码",
          icon: require("../../assets/newImages/user-icon4.png"),
        },
        {
          id: 5,
          title: "系统设置",
          icon: require("../../assets/newImages/user-icon5.png"),
        },
        {
          id: 6,
          title: "绑定邀请码",
          icon: require("../../assets/newImages/user-icon6.png"),
        },
        {
          id: 7,
          title: "Steam交易链接",
          icon: require("../../assets/newImages/user-icon7.png"),
        },
      ],
      isCounting: false,
      countdown: 30,

      bindname: false,
      namevalue: "", //姓名
      nameID: "", //身份证号码
      isAllowRealName: true,

      passwordshow: false, //修改密码弹窗
      phonevalue: "",
      passyzm: "", //修改密码验证码
      newpass: "", //新密码
      enterpass: "", //确认新密码

      bindcode: false,
      invite_code_bangding: "",

      bindsteam: false,
      steamphoneyzm: "",
      steam_url: "",
      oldsteamurl: "",

      usershow: false, //用户修改头像和修改昵称开关
      isupdataimg: "", //更新时的头像显示
      imgurlvalue, //模拟头像
      changehead: 0, //选择头像change
      nikenamevalue: "", //用户输入的昵称
      file: null, //用户选择文件
      typelist: [
        {
          id: 1,
          title: "更改昵称",
        },
        {
          id: 2,
          title: "更改头像",
        },
      ],
      acindex: 1,

      settings: false,
      bgmusictype: false,
      soundtype: false,
      soundClickHandler: null,
    };
  },

  computed: {
    ...mapState(["user", "globalMusic", "clickBgMusic"]),
  },
  methods: {
    sound() {
      // 切换 soundtype 状态
      this.soundtype = !this.soundtype;
      if (this.soundtype == true) {
        // 确保只添加一次事件监听器
        const playSound = () => {
          // 只在 soundtype 为 true 时播放音效
          if (this.soundtype) {
            this.clickBgMusic.currentTime = 0; // 从头开始播放
            this.clickBgMusic.play();
          }
        };

        // 添加鼠标点击事件监听器
        document.addEventListener("click", playSound);
        this.soundClickHandler = playSound; // 保存引用以便后续移除

        // 更新界面状态
        this.soundtype = true; // 假设你有一个方法来更新按钮状态
      } else {
        // 如果关闭声音时，暂停音效并重置时间
        this.clickBgMusic.pause();
        this.clickBgMusic.currentTime = 0; // 重置时间
        document.removeEventListener("click", this.soundClickHandler);
        // 更新界面状态
        this.soundtype = false; // 假设你有一个方法来更新按钮状态
      }
    },
    bgmusic() {
      if (this.globalMusic.paused) {
        this.globalMusic.play();
        this.globalMusic.loop = true;
        this.bgmusictype = true;
      } else {
        this.globalMusic.pause();
        this.bgmusictype = false;
      }
    },
    deletoldsteam() {
      this.steam_url = "";
    },
    updataimg() {
      //头像修改
      Set_avatar(this.isupdataimg).then((res) => {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.MyInfo();
        this.usershow = false;
      });
    },
    // 选择头像改变
    imgchange(v, i) {
      this.changehead = i;
      this.isupdataimg = v.url;
    },
    updataname() {
      // 获取用户输入昵称
      let res = this.nikenamevalue;
      // 昵称输入框正则验证
      let reg = /^[\u4e00-\u9fa5\w]{2,6}$/;
      if (!reg.test(res)) {
        this.$message({
          message:
            "最少输入2个字符，最多输入6个字符，可以包含汉字、字母、下划线",
          type: "error",
        });
      } else {
        Set_Name(res).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.MyInfo();
            this.usershow = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    changeindex(v) {
      this.acindex = v;
    },
    showupdata() {
      this.usershow = !this.usershow;
      this.isupdataimg = this.user.avatar;
      this.changehead = 0;
    },
    //设置Steam连接
    GetSteamUrl() {
      if (this.steam_url == "") {
        this.$message({
          message: "请输入steam链接",
          type: "error",
        });
        return;
      }
      if (this.phonevalue == "" || this.steamphoneyzm == "") {
        this.$message({
          message: "请输入手机号和验证码",
          type: "error",
        });
        return;
      }

      let params = {
        steam_url: this.steam_url,
        verify: this.steamphoneyzm,
        mobile: this.phonevalue,
      };
      SteamUrl(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.bindsteam = false;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
          this.bindsteam = false;
        }

        this.MyInfo();
      });
    },
    // 获得steam手机验证码
    getsteamyzm() {
      if (!myreg.test(this.phonevalue)) {
        //验证手机号格式是否正确
        this.$message({
          message: "手机号格式错误",
          type: "error",
        });
      } else {
        if (this.isCounting) {
          this.$message({
            message: "点的太快啦，请等一下",
            type: "error",
          });
          return;
        } // 防止重复发送
        this.isCounting = true;
        this.countdown = 30;
        const interval = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(interval);
            this.isCounting = false;
          }
        }, 1000);
        //4用来验证手机
        PostSendSms(this.phonevalue, 4).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    //绑定邀请人
    setinvite_code() {
      if (this.invite_code_bangding == "") {
        this.$message({
          message: "请输入邀请码",
          type: "error",
        });
      } else {
        set_inviter(this.invite_code_bangding).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.bindcode = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.bindcode = false;
          }
          this.MyInfo();
        });
      }
    },

    dopass() {
      if (this.passyzm == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      } else {
        if (!reg.test(this.newpass, this.enterpass)) {
          this.$message({
            message: "密码格式错误,最少输入6位最多输入12位密码,包含字母数字",
            type: "error",
          });
        } else {
          if (this.newpass != this.enterpass) {
            this.$message({
              message: "两次密码输入错误",
              type: "error",
            });
          } else {
            Reset_password(this.phonevalue, this.enterpass, this.passyzm).then(
              (res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });

                  setTimeout(() => {
                    this.loginOut();
                  }, 1000);
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              }
            );
          }
        }
      }
    },
    getpassyzm() {
      //获取修改密码验证码
      if (this.phonevalue) {
        if (!myreg.test(this.phonevalue)) {
          //验证手机号格式是否正确
          this.$message({
            message: "手机号格式错误",
            type: "error",
          });
        } else {
          if (this.isCounting) {
            this.$message({
              message: "点的太快啦，请等一下",
              type: "error",
            });
            return;
          } // 防止重复发送
          this.isCounting = true;
          this.countdown = 30;
          const interval = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(interval);
              this.isCounting = false;
            }
          }, 1000);
          PostSendSms(this.phonevalue, 3).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        }
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    //实名认证
    doentername() {
      if (!this.isAllowRealName) {
        this.$message.error("请勿频繁点击，10秒后再次尝试");
        return false;
      }
      this.isAllowRealName = false;
      setTimeout(() => {
        this.isAllowRealName = true;
      }, 10000);
      if (!ID.test(this.nameID)) {
        //验证身份证格式
        this.$message.error("身份证格式错误");
        return false;
      } else {
        const result = this.checkID18(this.nameID);
        if (!result) {
          this.$message.error("未满18岁");
          return false;
        }
        const params = {
          true_name: this.namevalue,
          card_id: this.nameID,
        };
        checkNameAndCardId(params).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
            this.MyInfo();
          } else {
            this.$message({
              message: res.data?.message,
              type: "error",
            });
          }
          this.bindname = false;
        });
      }
    },
    // 判断是否满18岁
    checkID18(IDCard) {
      //获取用户身份证号码
      var userCard = IDCard;
      //如果身份证号码为undefind则返回空
      if (!userCard) {
        return false;
      }
      //获取出生年月日
      var yearBirth = userCard.substring(6, 10);
      var monthBirth = userCard.substring(10, 12);
      var dayBirth = userCard.substring(12, 14);
      //获取当前年月日并计算年龄
      var myDate = new Date();
      var monthNow = myDate.getMonth() + 1;
      var dayNow = myDate.getDate();
      var age = myDate.getFullYear() - yearBirth;
      if (
        monthNow < monthBirth ||
        (monthNow == monthBirth && dayNow < dayBirth)
      ) {
        age--;
      }
      if (age >= 18) {
        return true;
      }
      return false;
    },

    ...mapMutations(["GetIndividualsShow", "Leave"]),
    ...mapActions({
      MyInfo: "Refresh",
    }),
    showitem(v) {
      switch (v) {
        case 1:
          if (this.user.true_name) {
            this.$message.error("已经实名，请勿重复填写");
          } else {
            this.bindname = !this.bindname;
            this.namevalue = "";
            this.nameID = "";
          }

          break;
        case 2:
          this.$router.push("/Best");
          break;
        case 3:
          this.$router.push("/Chargerecord");
          break;
        case 4:
          this.passwordshow = !this.passwordshow;
          this.phonevalue = "";
          this.passyzm = ""; //修改密码验证码
          this.newpass = ""; //新密码
          this.enterpass = ""; //确认新密码
          break;
        case 5:
          this.settings = !this.settings;
          break;
        case 6:
          this.bindcode = !this.bindcode;
          this.invite_code_bangding = "";
          break;
        case 7:
          this.bindsteam = !this.bindsteam;
          this.steam_url = this.user.steam_url;
          this.steamphoneyzm = "";
          this.phonevalue = "";
          break;
      }
    },
    //退出
    loginOut() {
      this.GetIndividualsShow(false);
      this.Leave();
      this.$router.push("/Home");
    },
  },
};
</script>

<style lang="scss" scoped>
.userbox {
  width: 100%;
  .topbox {
    width: 100%;
    height: 0.78rem;
    background: url("../../assets/newImages/user-topbg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lbox {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .headimgbox {
        width: 0.5rem;
        height: 0.5rem;
        background: url("../../assets/newImages/user-headbg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.12rem;
        img {
          width: 0.46rem;
          height: 0.46rem;
          border-radius: 50%;
        }
      }
      .userinfo {
        .username {
          font-weight: 500;
          font-size: 0.14rem;
          color: #ffffff;
        }
        .userid {
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-top: 0.12rem;
        }
      }
    }
    .rbox {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .price1 {
        width: 100%;
        display: flex;
        margin-bottom: 0.12rem;
      }
    }
  }
  .center {
    width: 3.35rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    .itembox {
      width: 1.6rem;
      height: 0.5rem;
      background: url("../../assets/newImages/user-itembg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.14rem;
      color: #ffedd1;
      margin-top: 0.12rem;
      padding-left: 0.1rem;
      box-sizing: border-box;
      .iconbox {
        width: 0.32rem;
        height: 0.32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.12rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tipsbox {
    width: 3.35rem;
    margin: 0.25rem auto;
    .tipstext {
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      img {
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.08rem;
      }
      a {
        color: #edd76b;
        text-decoration: underline;
      }
    }
  }
  .logout {
    margin-top: 0.5rem;
    .usertext {
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      text-decoration: underline;
      text-align: center;
      margin-bottom: 0.2rem;
    }
    .logoutbox {
      width: 2.79rem;
      height: 0.42rem;
      margin: 0 auto;
      background: url("../../assets/newImages/user-logoutbg.png") no-repeat;
      background-size: 100% 100%;
      font-weight: 600;
      font-size: 0.18rem;
      color: #53422e;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .frombox {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    .fromtitle {
      text-align: center;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
      margin-bottom: 0.12rem;
    }
    .frominput {
      width: 2.75rem;
      margin: 0 auto;
      .inputbox {
        width: 100%;
        margin-bottom: 0.16rem;
        position: relative;
        .inputtitle {
          font-weight: 400;
          font-size: 0.12rem;
          color: #ffffff;
          margin-bottom: 0.08rem;
        }
        .put {
          width: 100%;
          height: 0.38rem;
          background: #413f3e;
          text-decoration: none;
          border: 0;
          font-weight: 400;
          font-size: 0.14rem;
          color: #999999;
          text-indent: 0.12rem;
        }
        .putc {
          text-indent: unset;
          text-align: center;
          line-height: 0.38rem;
        }
        .yzmput {
          position: absolute;
          width: 0.96rem;
          height: 0.3rem;
          right: 0.05rem;
          top: 0.255rem;
          background: url("../../assets/newImages/send-code-bg.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.12rem;
          color: #312f2d;
        }
      }
      .tipstext {
        font-weight: 400;
        font-size: 0.12rem;
        color: #999999;
      }
      .textc {
        display: flex;
        justify-content: center;
      }
      .textd {
        text-decoration: underline;
      }
      .imgsbox {
        width: 100%;
        display: flex;
        height: 0.74rem;
        background: #040404;
        overflow-x: auto;
        .headimg {
          display: flex;
          justify-content: center;
          align-items: center;
          .l_imgbox {
            width: 0.48rem;
            height: 0.48rem;
            border-radius: 50%;
            margin: 0 0.06rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .acimgbox {
            width: 0.48rem;
            height: 0.48rem;
            border-radius: 50%;
            margin: 0 0.06rem;
            border: 0.02rem solid #ffec45;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.46rem;
              height: 0.46rem;
              border-radius: 50%;
            }
          }
        }
      }
      .setbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.45rem;
        p {
          width: 40%;
          text-align: right;
        }
        .setbut {
          width: 50%;
          .butbg1 {
            width: 0.96rem;
            height: 0.2rem;
            background: radial-gradient(
              18% 68% at 50% -18%,
              #ffec45 0%,
              #ffc700 100%
            );
            position: relative;
            .buttext {
              position: absolute;
              width: 0.28rem;
              height: 0.28rem;
              background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
              box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
                inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
                inset 1px 1px 1px 0px #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 550;
              font-size: 0.12rem;
              color: #151413;
              top: -0.03rem;
              right: 0.05rem;
            }
          }
          .butbg2 {
            width: 0.96rem;
            height: 0.2rem;
            background: #413f3e;
            position: relative;
            .buttext {
              position: absolute;
              width: 0.28rem;
              height: 0.28rem;
              background: linear-gradient(180deg, #e2e2e2 0%, #ffffff 100%);
              box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25),
                inset -1px -1px 1px 0px rgba(0, 0, 0, 0.3),
                inset 1px 1px 1px 0px #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 550;
              font-size: 0.12rem;
              color: #151413;
              top: -0.03rem;
              left: 0.05rem;
            }
          }
        }
      }
    }
    .btnbox {
      width: 100%;
      margin-top: 0.12rem;
      .btn {
        width: 1.4rem;
        height: 0.42rem;
        background: url("../../assets/newImages/user-sbtn.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.18rem;
        color: #312f2d;
        margin: 0 auto;
        font-family: PingFang SC, PingFang SC;
      }
      .bbtn {
        width: 2.8rem;
        height: 0.42rem;
        background: url("../../assets/newImages/long-btn-bg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 0.18rem;
        color: #312f2d;
        margin: 0 auto;
        font-family: PingFang SC, PingFang SC;
      }
    }
    .userheadimg {
      position: absolute;
      width: 0.84rem;
      height: 0.84rem;
      top: -0.6rem;
      right: 0.95rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .usertitle {
      width: 100%;
      height: 0.8rem;
      text-align: center;
      line-height: 1rem;
      font-weight: 500;
      font-size: 0.18rem;
      color: #ffffff;
    }
    .changebox {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.38rem;
      background: #161514;
      margin: 0 auto 0.3rem;
      .typeitem {
        width: 0.96rem;
        height: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
      }
      .actypeitem {
        background: url("../../assets/newImages/send-code-bg.png") no-repeat;
        background-size: 100% 100%;
        color: #151413;
      }
    }
  }
}
</style>
